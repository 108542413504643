<template>
  <div class="reserve">
    <TopBar pageTitle="预约登记" />
    <div class="info">
      <p class="tip">请填写您的个人信息和具体需求，我们将会在24小时内与您联系</p>
      <el-form ref="form" :model="formData" :rules="rules">
        <el-form-item label="请选择合适时间" required>
          <el-form-item prop="start_time" class="first">
            <el-date-picker v-model="formData.start_time" type="date" placeholder="请选择学习开始时间"></el-date-picker>
          </el-form-item>
          <el-form-item prop="end_time" class="endTime">
            <el-date-picker v-model="formData.end_time" type="date" placeholder="请选择学习结束时间"></el-date-picker>
          </el-form-item>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="formData.name" placeholder="请填写您的姓名"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input type="number" v-model="formData.phone" placeholder="请填写您的联系电话"></el-input>
        </el-form-item>
        <el-form-item label="单位">
          <el-input v-model="formData.unit" placeholder="请填写您的工作单位"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="formData.email" placeholder="请填写您的邮箱"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" class="textarea" v-model="formData.remark" placeholder="请填写备注，选填"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="btn-box">
      <button class="btn reset" @click="reset">重置</button>
      <button class="btn sub" @click="subBtn('form')">立即预约</button>
    </div>
  </div>
</template>
<script>
import TopBar from '@/m/common/topBar.vue';
import { mapMutations} from "vuex";
export default {
  name: "m-reserve",
  data() {
    return {
      id: '',          //ID
      reserveType: 0,  //预约类型
      formData: {
        start_time: '',
        end_time: '',
        name: '',
        phone: '',
        unit: '',
        email: '',
        remark: '',
        did:'',
      },
      rules: {
        start_time: [
          { required: true, message: '请选择学习时间', trigger: 'blur' },
        ],
        end_time: [
          { required: true, message: '请选择学习时间', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请填写您的姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请填写您的联系电话', trigger: 'blur' },
          { min: 11, max: 11, message: '请填写正确的电话号码', trigger: 'blur' },
        ],
        email: [
          { type: 'email', message: '请填写正确的邮箱', trigger: 'blur' },
        ],
      }
    };
  },
  components: {
    TopBar
  },
  mounted() {
    this.id = this.$route.params.id;
    this.reserveType = this.$route.params.type;
    this.siteInfo = JSON.parse(localStorage.getItem('siteInfo'))
    this.formData.did = this.siteInfo?this.siteInfo.id:''
  },
  methods: {
    ...mapMutations(['ShowTipModal']), //tip弹窗
    //重置
    reset(){
      for(let i in this.formData) {
        this.formData[i] = '';
      }
    },
    //提交
    subBtn(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.formData.type = this.reserveType;
          this.$axios.post("https://kskapi.kskstudy.com/om/v1/teacherSubscribeForm", this.formData).then(res => {
            if(res.code == 0){
              this.ShowTipModal({
                text: '预约成功',     //提示弹窗内容
                ico: 'success',
                fun: ()=>{
                  this.$router.back(-1);
                }
              })
            }else{
              this.ShowTipModal({
                text: res.msg,     //提示弹窗内容
              })
            }
          })
        }else{
          this.ShowTipModal({
            text: '有未填写的内容',     //提示弹窗内容
          })
        }
      })
    }
  },
};
</script>
<style scoped lang="scss">
.reserve{
  padding-bottom: 140px;
}
.info{
  padding: 40px 36px;
  .tip{
    padding: 14px 28px;
    background-color: #F9F9F9;
    border-radius: 6px;
    font-size: 22px;
    color: #999;
    line-height: 30px;
  }
}
.btn-box{
  width: 90%;
  height: 140px;
  margin: 0 auto;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eee;
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 2;
  .btn{
    width: 320px;
    height: 70px;
    line-height: 70px;
    border-radius: 30px;
    font-size: 28px;
    &.reset{
      color: #0065DF;
      background-color: #E5F0FC;
    }
    &.sub{
      color: #fff;
      background-color: #0065DF;
    }
  }
}
::v-deep .el-form{
  padding-top: 40px;
  .el-form-item{
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
    &.endTime{
      margin-left: 120px;
    }
    // .first{
    //   margin-bottom: 40px !important;
    // }
    // .el-form-item__label{
    //   display: block;
    //   width: 100% !important;
    //   margin: 0 0 15px 0;
    //   text-align: left;
    //   font-size: 32px;
    //   color: #000;
    //   font-weight: 500;
    //   float: none;
    // }
    // .el-form-item__content,
    // .el-date-editor{
    //   width: 100% !important;
    //   margin: 0 !important;
    // }
  }
  // .el-input__inner,
  // .el-input__icon{
  //   height: 100px;
  //   line-height: 100px;
  //   font-size: 24px;
  // }
  // .el-input__icon{
  //   font-size: 30px;
  //   margin-right: 20px;
  // }
  .textarea,
  textarea{
    // height: 200px;
    resize: none;
  }
}

</style>